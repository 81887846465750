<template>
  <div :id="teamKey" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
    <div class="card person border-light" style="margin-top: 90px">
      <div class="card-img-top" style="margin-top: -90px; text-align: center">
        <img
          :src="`/img/${teamKey}.jpg`"
          style="border-radius: 50%; width: 180px; border: 12px solid #f8f9fa"
        />
      </div>
      <div class="card-body flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <h5>{{ $t("team.members." + teamKey + ".name") }}</h5>
            <p>{{ $t("team.members." + teamKey + ".title") }}</p>
            <h6>Ausbildung</h6>
            <p v-html="$t('team.members.' + teamKey + '.education')"></p>
          </div>
          <div class="flip-card-back">
            <h6>Hobbys</h6>
            <p>{{ $t("team.members." + teamKey + ".hobbies") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamMember",
  props: {
    teamKey: String,
  },
  data() {},
  components: {},
};
</script>

<style scoped>
h5 {
  font-size: 1.15em;
}
</style>

<template>
    <div id="team" class="container-fluid bg-light">
        <div class="section container-xxl">
            <h5>Wir stellen uns vor</h5>
            <h2 class="text-primary">Ein Team von Profis</h2>
            <div class="row g-5">
              <TeamMember v-for="member in members" :teamKey=member :key=member />






            </div>
        </div>

    </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import TeamMember from '@/components/TeamMember'
import members from '@/locales/de.json'

export default {
  name: 'Team',
        data() {
            return {
              members: Object.keys(members.team.members)
            };
        },
  components: {
    Footer,
    TeamMember
  }
}
</script>

<style scoped>
  .container-fluid {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h2 {
    margin-bottom: 30px;
  }
</style>